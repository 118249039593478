/**
 * Layout for landing page
 * with Gatsby
 *
 * Author : vijay verma
 * Created : 04-Jan-2020
 */

import React from "react"
import { Link } from "gatsby"

import "../fonts/fonts.css"
import "./layout.scss"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header = <></>
    return (
      <div>
        <header>{header}</header>
        <main>{children}</main>
      </div>
    )
  }
}

export default Layout
