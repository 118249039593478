/**
 * Short Hero Component
 * Author : vijay verma
 * Created : 28-Jan-2020
 */
 import React from "react"
 import { useStaticQuery, graphql } from "gatsby"
 import Image from "gatsby-image"


 const Hero = () => {
   return (
     <>
     <section className="sushi-header">
         <div className="container">
           <div className="sushi-hero">
             <img src="/sushi-logo.png"/>
             <p>Zomato's very own Design System provides components that reflect our brand promise, and create simple and intuitive user experiences across our platforms.</p>
           </div>
         </div>
     </section>
     <section className="doc_section">
       <div className="container">
         <div className="sushi-doc">
           <div className="project-wrap">

           <div className="project-box">
             <a href="https://medium.com/zomato-technology/zomatos-new-sushi-design-system-d7f4f98664c5" className="project">
              <div className="project-info">
                <img src="/get-start.png"/>
                <h2>Get Started</h2>
                <p>Explore Zomato’s Design System to create intuitive, consistent and beautiful products with ease.</p>
                <div className="link-info">
                  <h4 className="lnk">Read more <img src="/open.svg"/></h4>
                </div>
              </div>
             </a>
           </div>

           <div className="project-box">
             <a href="#" className="project">
              <div className="project-info">
                <img src="/visual.png"/>
                <h2>Visual Guidelines</h2>
                <p>Build clean interfaces with the help of principles, standards, and usage guidelines of Sushi.</p>
                <div className="link-info">
                  <h4 className="soon">Coming Soon</h4>
                </div>
              </div>
             </a>
           </div>

           <div className="project-box">
             <a href="https://figma.com/@zomato" className="project">
              <div className="project-info">
                <img src="/components.png"/>
                <h2>Figma Components</h2>
                <p>Design layouts, flows, wireframes, and prototypes with the help of components in Figma.</p>
                <div className="link-info">
                  <h4 className="lnk">Sushi toolkit <img src="/open.svg"/></h4>
                </div>
              </div>
             </a>
           </div>

           </div>
         </div>
       </div>
     </section>
     <section className="dev_section">
       <div className="container">
         <div className="dev-wrap">
           <div className="dev-txt">
             <h1>Development</h1>
             <p>An open source library with custom components and controls to help you get started with development.</p>
           </div>
           <div className="project-wrap dev-project-wrap">

             <div className="project-box">
               <a href="#" className="project">
                <div className="project-info">
                  <img src="/android-icon.png"/>
                  <div className="desc-info">
                   <h2>Android Kit</h2>
                    <h4 className="soon">Coming Soon</h4>
                  </div>
                </div>
               </a>
             </div>

             <div className="project-box">
               <a href="#" className="project">
                <div className="project-info">
                  <img src="/apple-icon.png"/>
                  <div className="desc-info">
                   <h2>iOS Kit</h2>
                    <h4 className="soon">Coming Soon</h4>
                  </div>
                </div>
               </a>
             </div>

             <div className="project-box">
               <a href="#" className="project">
                <div className="project-info">
                  <img src="/web-icon.png"/>
                  <div className="desc-info">
                   <h2>Web Kit</h2>
                    <h4 className="soon">Coming Soon</h4>
                  </div>
                </div>
               </a>
             </div>

           </div>
         </div>
       </div>
     </section>
     <section className="sushi_footer">
       <div className="container">
         <div className="sushi-f-wrap">
         <img src="/sushi-black.png"/>
         <p>Sushi is an open source project designed and developed at Zomato.</p>
         <a href="https://www.zomato.com/careers">We’re hiring Designers and Engineers!</a>
         </div>
       </div>
     </section>
     </>
   )
 }

 export default Hero
