/**
 * Short Footer Component
 * Author : vijay verma
 * Created : 05-Jan-2020
 */
 import React from "react"
 import { useStaticQuery, graphql } from "gatsby"
 import Image from "gatsby-image"


 const Footer = () => {
   return (
     <footer className="footer_section">
          <div className="container">
          <div className="foot">
            <div className="f-menu-wrap">
              <div className="f-brand">
                <a href="https://zomato.com"><img src="/zomato-black.svg" /></a>
              </div>
              <ul className="foot-menu">
                <li>
                  <a href="https://zomato.github.io/">Open-source</a>
                </li>
                <li>
                  <a href="https://www.zomato.com/about">About</a>
                </li>
                <li>
                  <a href="https://www.zomato.com/blog">Blog</a>
                </li>
              </ul>
            </div>
            <ul className="social-l">
              <li className="icon-lnk">
                <a href="https://www.github.com/zomato/" target="__blank"><img src="/github.svg"/></a>
              </li>
              <li className="icon-lnk">
                <a href="https://dribbble.com/zomato" target="__blank"><img src="/dribbble.svg"/></a>
              </li>
              <li className="icon-lnk">
                <a href="https://www.linkedin.com/company/zomato/" target="__blank"><img src="/linkedin.svg"/></a>
              </li>
              <li className="icon-lnk">
                <a href="https://www.twitter.com/zomato/" target="__blank"><img src="/twitter.svg"/></a>
              </li>
            </ul>
            </div>
          </div>
      </footer>
   )
 }

 export default Footer
